import { AnimatePresence, motion } from 'framer-motion'
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react'
import { IMAGE_FILTER } from '../../../utils/detectWebPSupported'
import { isNotNull } from '../../../utils/nonNull'
import { Button } from '../../Button'
import { Grid } from '../../Grid'
import { HeaderThree, HeaderTwo } from '../../Typography/typography.stories'
import LeftArrow from './LeftArrow'
import RightArrow from './RightArrow'
import classes from './desktopFeaturedCarousel.module.scss'

export type SlideType = {
  component?: 'home_featured_projects_slides'
  button: string
  button_link: {
    cached_url: string
  }
  foregroundImage: {
    filename: string
  }
  backgroundImage: {
    filename: string
  }
  slideList: {
    component?: 'home_featured_projects_slide_list-item'
    name: string
  }[]
  slideListTitle: string
  slideName: string
}

export type CarouselType = {
  data: SlideType[]
}

const DesktopFeaturedCarousel: React.FC<PropsWithChildren<CarouselType>> = ({ data }) => {
  const [animState, setAnim] = useState<number>(0)
  const timeoutRef: React.MutableRefObject<NodeJS.Timeout> | React.MutableRefObject<null> = useRef(null)
  const [isHovering, setIsHovering] = useState(false)

  const resetTimeout = () => {
    //@ts-ignore
    clearTimeout(timeoutRef.current)
  }

  const handleMouseOver = () => {
    setIsHovering(true)
  }

  const handleMouseOut = () => {
    setIsHovering(false)
  }

  useEffect(() => {
    if (data.length > 1) {
      resetTimeout()
      //@ts-ignore
      timeoutRef.current = setTimeout(
        () =>
          setAnim((prevIndex) =>
            prevIndex === data.length - 1 ? 0 : prevIndex + 1,
          ),
        isHovering ? 100000 : 7000,
      )

      return () => {
        resetTimeout()
      }
    }
  }, [animState, isHovering])

  return data[animState] ? (
    <Grid container>
      <div
        className={classes.featuredCarouselContainer}
      >
        <div className={classes.slideContainer} onMouseEnter={handleMouseOver} onMouseLeave={handleMouseOut}>
          <div className={classes.textContainer}>
            {isNotNull(data?.[animState]?.slideName) ? (
              <AnimatePresence exitBeforeEnter>
                <motion.div key={animState + 'h3'} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ delay: 0.25, duration: 0.5 }}>
                  <HeaderThree>{data?.[animState]?.slideName || ''}</HeaderThree>
                </motion.div>
              </AnimatePresence>) : null
            }
            {isNotNull(data?.[animState]?.slideListTitle) ? (
              <AnimatePresence exitBeforeEnter>
                <motion.div key={animState + 'h2'} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ delay: 0.25, duration: 0.5 }}>
                  <HeaderTwo>{data?.[animState]?.slideListTitle || ''}</HeaderTwo>
                </motion.div>
              </AnimatePresence>) : null
            }
            <ul className="projectListingList">
              {
                data?.[animState]?.slideList.map((li, idx) => {
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <AnimatePresence exitBeforeEnter>
                      <motion.li key={animState + 'item' + idx} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ delay: 0.25, duration: 0.5 }} className={classes.listItem}>
                        {li.name}
                      </motion.li>
                    </AnimatePresence>
                  )
                })
              }
            </ul>

            {data?.[animState]?.button_link?.cached_url && (
              <AnimatePresence exitBeforeEnter>
                <motion.div key={animState + 'caseStudy'} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ delay: 0.25, duration: 0.5 }}>
                  <a href={`/${data?.[animState]?.button_link?.cached_url}`} className={classes.buttonLink}>
                    <Button className={'caseStudyButton'} variant={'primary'} icon={''}>{data?.[animState]?.button}</Button>
                    <div className={classes.buttons}/>
                  </a>
                </motion.div>
              </AnimatePresence>
            )}

            {classes.leftArrow && classes.rightArrow && data.length > 1 && <div className={classes.buttonContainer}>
              <motion.div
                key={'left'}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ delay: 0.25 }}
                onClick={()=>{setAnim(animState !== 0 ? animState - 1 : data.length-1 )}}
                className={classes.sliderButton}
              >
                <LeftArrow className={classes.leftArrow}/>
              </motion.div>
              <motion.div
                key={'right'}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ delay: 0.25 }}
                onClick={()=>{setAnim(animState < data.length - 1 ? animState + 1 : 0)}}
                className={classes.sliderButton}
              >
                <RightArrow className={classes.rightArrow}/>
              </motion.div>
            </div>}
          </div>
          <div className={classes.imageContainer}>
            {data?.[animState]?.backgroundImage?.filename !== '' ? (
              <AnimatePresence exitBeforeEnter>
                <motion.div key={animState + 'bg'} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ delay: 0.25, duration: 0.5 }}>
                  {data?.[animState]?.backgroundImage?.filename.split('.').pop() === 'svg' ? (
                    <div style={{ position: 'absolute', width: '120%', height: '120%', top: '-5%', left: '-14%' }}>
                      <object data={`${data?.[animState]?.backgroundImage?.filename}`} type="image/svg+xml" width="100%" height="100%">image</object>
                    </div>
                  ) : (
                    <img src={`${data?.[animState]?.backgroundImage?.filename}${IMAGE_FILTER}`} className={classes.backgroundImage} alt="background" />
                  )}

                </motion.div>
              </AnimatePresence>
            ) : null
            }
            <AnimatePresence exitBeforeEnter>
              <motion.div key={animState + 'image'} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ delay: 0.25, duration: 0.5 }}>
                <div>
                  <img src={`/_next/image?url=${encodeURIComponent(`${data?.[animState]?.foregroundImage?.filename}${IMAGE_FILTER}`)}&w=1920&q=90`} alt="carousel featured project visual"  className={classes.laptopImage}/>
                </div>
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
      </div>
    </Grid>
  ) : null
}

export default DesktopFeaturedCarousel
