
const LeftArrow = ({ className }:{className:string}) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" fill="#a0a7af" width="43.991" height="43.83" viewBox="0 0 43.991 43.83">
      <g id="Group_3000" data-name="Group 3000" transform="translate(-383.25 -2641.666)">
        <g id="Group_2946" data-name="Group 2946" transform="translate(384 2642.416)">
          <path id="Path_5137_-_Outline" data-name="Path 5137 - Outline" d="M3692.98,2492.61h0a25.4,25.4,0,0,1,8.545,1.477l.012,0a17.628,17.628,0,0,1,5.612,3.415,20.276,20.276,0,0,1,3.975,4.978,29.738,29.738,0,0,1,3.626,12.319l0,.044a23.387,23.387,0,0,1-5.9,16.093l-.022.023a22.639,22.639,0,0,1-4.393,3.529l-.052.032-.056.023a25.244,25.244,0,0,1-27.928-6l-.024-.025-.021-.027a29.749,29.749,0,0,1-2.411-3.578,23.79,23.79,0,0,1-3.174-11.1v-.018a20.915,20.915,0,0,1,10.725-18.449l.028-.015A25.593,25.593,0,0,1,3692.98,2492.61Zm8.036,2.888a23.962,23.962,0,0,0-18.8,1.168,19.415,19.415,0,0,0-9.943,17.11,22.289,22.289,0,0,0,2.974,10.389,28.237,28.237,0,0,0,2.27,3.373,23.743,23.743,0,0,0,26.188,5.646,21.127,21.127,0,0,0,4.039-3.251,21.89,21.89,0,0,0,5.514-15.027,28.224,28.224,0,0,0-3.429-11.672A16.979,16.979,0,0,0,3701.016,2495.5Z" transform="translate(-3671.519 -2493.36)" />
        </g>
        <g id="Group_2947" data-name="Group 2947" transform="matrix(-0.719, -0.695, 0.695, -0.719, 405.891, 2675.945)">
          <path id="Path_5147" data-name="Path 5147" d="M.857,14.408c8.434-8.949,3.148-3.556,6.4-6.894,1.581-1.629,2.3-2.188,3.929-3.775C12.9,2.065,12.925,2.436,14.62.756a.438.438,0,0,0-.5-.7h0c-2.031,1.192-2.062,1.182-4.021,2.5a20.206,20.206,0,0,0-3.325,2.7C3.241,8.372,2.176,9.355,0,13.7" transform="translate(0 1.957)" />
          <path id="Path_5147_-_Outline" data-name="Path 5147 - Outline" d="M1.039,14.58l-.364-.343c3.873-4.11,4.937-5.285,5.111-5.511a.933.933,0,0,1,.2-.267C6.14,8.3,6.433,8,7.074,7.34c.936-.965,1.584-1.567,2.271-2.2.49-.455,1-.926,1.662-1.575a15.534,15.534,0,0,1,1.733-1.5,14.2,14.2,0,0,0,1.7-1.486.181.181,0,0,0,0-.249.156.156,0,0,0-.2-.058L13.5.708c-1.342.784-1.583.926-3.261,2.057A13.893,13.893,0,0,0,7.991,4.493c-.257.237-.576.531-1.052.952C3.466,8.507,2.418,9.431.223,13.809l-.447-.224C1.926,9.3,2.967,8.281,6.608,5.07c.473-.418.79-.71,1.044-.945A14.26,14.26,0,0,1,9.958,2.35c1.691-1.141,1.966-1.3,3.289-2.074l.746-.437a.645.645,0,0,1,.851.183.674.674,0,0,1-.047.91,14.8,14.8,0,0,1-1.756,1.531,15.1,15.1,0,0,0-1.685,1.455c-.669.653-1.178,1.126-1.671,1.584C9,6.135,8.36,6.733,7.433,7.688c-.7.716-1.072,1.1-1.175,1.217a.663.663,0,0,1-.106.162c-.093.115-.278.324-.564.639C4.814,10.558,3.283,12.2,1.039,14.58Z" transform="translate(0 1.957)" />
          <path id="Path_5148" data-name="Path 5148" d="M12.392,1.115A1.14,1.14,0,0,0,10.976.034C7.5.587,3.944.843.58,1.928A.789.789,0,0,0,1,3.449c3.029-.641,6.093-.721,9.147-1.068,0,1.148,0,2.3-.059,3.453a31.8,31.8,0,0,0-.2,4.718c.088.737,1.211,1.307,1.6.419,1.307-2.925.905-6.7.905-9.855Z" transform="translate(3.812 0)" />
          <path id="Path_5148_-_Outline" data-name="Path 5148 - Outline" d="M11.253-.25a1.4,1.4,0,0,1,1.389,1.36v.005c0,.585.014,1.182.028,1.815.061,2.678.131,5.712-.955,8.143a.919.919,0,0,1-.855.6,1.3,1.3,0,0,1-1.218-1.092,15.08,15.08,0,0,1,.075-3.129c.05-.535.1-1.089.121-1.628C9.891,4.769,9.9,3.7,9.9,2.66c-.9.1-1.807.175-2.687.25a50.563,50.563,0,0,0-6.152.782,1.039,1.039,0,0,1-.55-2A44.309,44.309,0,0,1,8.169.184c.906-.123,1.842-.25,2.757-.4a1.387,1.387,0,0,1,.3-.039Zm.889,1.368A.9.9,0,0,0,11.253.25h-.02a.886.886,0,0,0-.2.026l-.022,0c-.923.147-1.866.275-2.778.4A43.91,43.91,0,0,0,.656,2.166l-.01,0A.539.539,0,1,0,.932,3.208l.015,0a50.885,50.885,0,0,1,6.219-.792c.968-.083,1.969-.168,2.951-.28L10.4,2.1v.28c0,1.134,0,2.307-.059,3.465-.019.55-.072,1.111-.122,1.653a14.707,14.707,0,0,0-.076,3.024.8.8,0,0,0,.722.65.427.427,0,0,0,.4-.3c1.041-2.33.976-5.177.913-7.93C12.156,2.307,12.142,1.708,12.142,1.118Z" transform="translate(3.812 0)" />
        </g>
      </g>
    </svg>
  )
}

export default LeftArrow
