import React, { PropsWithChildren } from 'react'
import Slider from 'react-slick'
import { Button } from '../../../components/Button'
import { IMAGE_FILTER } from '../../../utils/detectWebPSupported'
import { FadeInWhenVisible } from '../../FadeInWhenVisible'
import { HeaderThree, HeaderTwo } from '../../Typography/typography.stories'
import { SlideType } from '../Desktop'
import classes from './mobileFeaturedCarousel.module.scss'

const MobileFeaturedCarousel: React.FC<PropsWithChildren<{ data: SlideType[] }>> = ({ data }) => (
  <div className={classes.responsiveCarouselContainer}>
    <Slider
      dots
      autoplay={false}
      autoplaySpeed={3500}
      speed={1500}
    >
      {
        data.map((item, idx) => {
          return(
            <div key={idx} className={classes.respSlideContainer}>
              <div className={classes.respTextContainer}>
                <FadeInWhenVisible key={idx}  animation="easyFadeIn" >
                  <HeaderThree>
                    { item.slideName }
                  </HeaderThree>
                </FadeInWhenVisible>
              </div>
              <div className={classes.imageContainer}>
                {item.backgroundImage.filename !== '' ? (
                  <FadeInWhenVisible key={idx}   animation="easyFadeInStill" delay={0.6}>
                    {item.backgroundImage.filename.split('.').pop() === 'svg' ? (
                      <div className={classes.svgImageWrapper}>
                        <object data={`${item.backgroundImage.filename}`} type="image/svg+xml" width="100%" height="100%">image</object>
                      </div>
                    ) : (
                      <img src={`/_next/image?url=${encodeURIComponent(`${item.backgroundImage.filename}${IMAGE_FILTER}`)}&w=1200&q=80`} className={classes.backgroundImage} alt="background" />
                    )}
                  </FadeInWhenVisible>
                ) : null
                }
                <FadeInWhenVisible key={idx}  animation="easyFadeIn" delay={0.3}>
                  <img className={classes.laptopImage}  src={`/_next/image?url=${encodeURIComponent(`${item.foregroundImage.filename}${IMAGE_FILTER}`)}&w=1200&q=80`} alt="carousel featured project visual"/>
                </FadeInWhenVisible>
              </div>

              <div className={classes.respTextContainer}>
                <FadeInWhenVisible key={idx}   animation="easyFadeIn" delay={0.6} >
                  <HeaderTwo>
                    { item.slideListTitle }
                  </HeaderTwo>
                </FadeInWhenVisible>
              </div>
              {item?.button_link?.cached_url && (
                <a href={`/${item?.button_link?.cached_url}`} className={classes.buttonLink}>
                  <FadeInWhenVisible key={idx}   animation="easyFadeIn" delay={0.9} >
                    <Button variant={'no-icon'} icon={''} className={classes.slideButton}>{item?.button}</Button>
                  </FadeInWhenVisible>
                </a>
              )}
            </div>
          )
        })
      }
    </Slider>
  </div>
)


export default MobileFeaturedCarousel
