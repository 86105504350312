import React, { PropsWithChildren, useEffect, useState } from 'react'
import { useMediaQuery } from '../../hooks/useMediaQuery'
import DesktopFeaturedCarousel, { SlideType } from './Desktop'
import MobileFeaturedCarousel from './Mobile'
import classes from './featuredCarousel.module.scss'

export type FeaturedProjectsProps = {
  component?: 'home_featured_projects'
  slides: SlideType[]
}

export const FeaturedProjects: React.FC<PropsWithChildren<{ slice: FeaturedProjectsProps }>> = ({ slice })=> {
  const isMobile = useMediaQuery('(max-width: 1023px)')
  const { slides } = slice
  const [initial, setInitial] = useState<number>(0)

  useEffect(() => {
    if (initial >= slides.length || initial < 0) setInitial(0)
  }, [initial, setInitial])

  return (
    <div className={classes.featuredProjectsWrapper}>
      {!isMobile ?
        (
          <div className={classes.container}>
            <DesktopFeaturedCarousel data={slides} />
          </div>
        )
        :
        (<MobileFeaturedCarousel data={slides} />)}
    </div>
  )
}


export default FeaturedProjects
